import { nanoid } from 'nanoid';
import { getUA } from 'react-device-detect';
import ReactPixel from 'react-facebook-pixel';
// import { TrackIdentify } from 'klaviyo-sdk';

import { post } from '../../utils/http';
import * as storage from '../../utils/storage';
import { pixelSetup } from '../facebook-pixel';
import { store as reduxStore } from '../../app/App';
import { getClientIp } from '../../utils/getClientIp';
import {
  isDevelopment,
  isProduction,
  metaPixelTestCode,
} from '../../utils/envHandler';
import { readCookie } from '../../utils/readCookie';

const klaviyo = {};

// const klaviyoApiKey = process.env.REACT_APP_KLAVIYO_API_KEY;

// const klaviyo = !isDevelopment
//   ? eventApi
//   : { trackPost() { }, identifyPost() { } };

const pixelId = process.env.REACT_APP_META_PIXEL_ID;

// const klaviyoTrack = klaviyo.trackPost.bind(klaviyo);

// klaviyo.trackPost = async function (
//   eventName,
//   klaviyoEmail,
//   properties = null,
// ) {
//   try {
//     const data = {
//       token: klaviyoApiKey,
//       event: eventName,
//       customer_properties: {
//         $email: klaviyoEmail,
//       },
//     };
//     if (properties) data.properties = properties;

//     await klaviyoTrack({ data: JSON.stringify(data) });
//   } catch (err) {
//     console.log('klaviyo error', err);
//   }
// };

klaviyo.trackPost = async function (
  eventName,
  klaviyoEmail,
  properties = null,
) {
  try {
    await post('/analytics/klaviyo', null, {
      eventName,
      klaviyoEmail,
      properties,
    });
  } catch (err) {
    console.log('klaviyo error', err);
  }
};

klaviyo.identifyPost = async function (data) {
  try {
    await post('/analytics/klaviyo/identify', null, {
      data,
    });
  } catch (err) {
    console.log('klaviyo identify error', err);
  }
};

const umami = window.umami;
const _learnq = window._learnq || [];
let dataLayer = window.dataLayer || [];
let clientIp = '';
getClientIp()
  .then((res) => (clientIp = res))
  .catch(console.log);

const sendFbDataToServer = (eventName, userInfo, data) => {
  const url = `/analytics/fb`;
  const fbp = document.cookie
    .split(';')
    .find((c) => c.trim().startsWith('_fbp'))
    ?.split('=')
    ?.pop();

  const fbc = readCookie('_fbc');

  userInfo.ua = getUA;
  userInfo.clientIp = clientIp;
  let eventID = '';
  if (typeof eventName !== 'string') {
    eventID = eventName.eventID;
    eventName = eventName.eventName;
  }
  const { user } = reduxStore.getState();
  const externalId = storage.get('external_id');
  post(url, null, {
    fbp,
    fbc,
    data,
    eventID,
    eventName,
    externalId,
    testCode: metaPixelTestCode,
    sourceUrl: window.location.href,
    userInfo: { ...user, ...userInfo },
  }).catch(console.log);
};

export const a_init = () => {
  if (isDevelopment) return;
  // ReactGA.initialize(process.env.REACT_APP_GA_ID);
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const { klaviyoEmail } = reduxStore.getState().user;
  window.snaptr('init', process.env.REACT_APP_SNAPCHAT_API_KEY, {
    user_email: klaviyoEmail,
  });
};

export const a_getStartedLoaded = () => {
  if (isDevelopment) return;
  const { app, coupon } = reduxStore.getState();
  const b_disc = coupon?.coupon?.b_disc || 0;
  const discountedPriceOneBoard = (
    app.priceOne -
    (app.priceOne / 100) * b_disc
  ).toFixed(1);

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'product-impression',
    eventLabel: '',
    ecommerce: {
      currencyCode: coupon.currency || 'USD',
      impressions: [
        {
          name: 'board',
          id: 'board',
          price: discountedPriceOneBoard,
          category: '',
          brand: 'StoryBoards',
        },
      ],
    },
  });
};

export const a_PortalClicked = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = 'Vip Portal Clicked';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_VipModalHandled = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = 'Vip Modal Handled';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_PortalLoggedIn = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = 'Vip Portal Logged In';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_ShopNowClicked = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = 'Shop Now Clicked';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_GiftCreditClicked = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = 'Gift Credit Clicked';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_PortalAction = (action) => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const eventName = `Vip Portal Action: ${action}`;
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_letsCreate = () => {
  if (isDevelopment) return;
  const { app, user, coupon } = reduxStore.getState();
  const b_disc = coupon?.coupon?.b_disc || 0;
  const discountedPriceOneBoard = (
    app.priceOne -
    (app.priceOne / 100) * b_disc
  ).toFixed(1);
  const { klaviyoEmail } = user;

  const eventName = 'Lets Create';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'product-click',
    eventLabel: '',
    ecommerce: {
      currencyCode: coupon.currency || 'USD',
      click: {
        products: [
          {
            name: 'board', // Product name
            id: 'board', // Product identifier/SKU
            price: discountedPriceOneBoard, // Product price (including discount amount)
            category: '', // Product category
            brand: 'StoryBoards',
          },
        ],
      },
    },
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_viewBoardsPage = () => {
  if (isDevelopment) return;
  const { app, boards, user, coupon } = reduxStore.getState();
  const b_disc = coupon?.coupon?.b_disc || 0;
  const discountedPriceOneBoard = (
    app.priceOne -
    (app.priceOne / 100) * b_disc
  ).toFixed(1);
  const product_id = coupon.coupon?.name || 'sb01';

  const fbData = {
    contents: [{ id: product_id, quantity: boards.boards?.length || 0 }],
    content_type: 'product',
  };
  const eventName = 'ViewContent';
  const eventID = `${eventName}.${nanoid(8)}`;
  pixelSetup(user);
  ReactPixel.track('PageView');
  window.fbq &&
    window.fbq('trackSingle', pixelId, eventName, fbData, { eventID });
  sendFbDataToServer(
    { eventID, eventName },
    { email: user.email, phone: user.phone },
    fbData,
  );

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'product-details-view',
    eventLabel: '',
    ecommerce: {
      currencyCode: coupon.currency || 'USD',
      detail: {
        products: [
          {
            name: 'board',
            id: 'board',
            price: discountedPriceOneBoard,
            category: '',
            brand: 'StoryBoards',
          },
        ],
      },
    },
  });
};

export const a_photoUpload = () => {
  if (isDevelopment) return;
  const { app, user, coupon } = reduxStore.getState();
  const b_disc = coupon?.coupon?.b_disc || 0;
  const discountedPriceOneBoard = (
    app.priceOne -
    (app.priceOne / 100) * b_disc
  ).toFixed(1);
  pixelSetup(user);
  const product_id = coupon.coupon?.name || 'sb01';
  let eventName = 'Photo Upload';
  let eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer(
    { eventID, eventName },
    { email: user.email, phone: user.phone },
  );

  const fbData = {
    contents: [{ id: product_id, quantity: 1 }],
    content_type: 'product',
  };
  eventName = 'AddToCart';
  eventID = `${eventName}.${nanoid(8)}`;
  pixelSetup(user);
  window.fbq &&
    window.fbq('trackSingle', pixelId, eventName, fbData, { eventID });
  sendFbDataToServer(
    { eventID, eventName },
    { email: user.email, phone: user.phone },
    fbData,
  );

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Photo Upload',
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  umami?.track('photo_upload');

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'add-to-cart',
    eventLabel: '',
    ecommerce: {
      currencyCode: coupon.currency || 'USD',
      add: {
        products: [
          {
            name: 'board', // Product name
            id: 'board', // Product identifier/SKU
            price: discountedPriceOneBoard, // Product price (including discount amount)
            category: '', // Product category
            brand: 'StoryBoards',
            quantity: 1, // Should be 1 if it is possible to upload only 1 image per time
          },
        ],
      },
    },
  });

  window.snaptr('track', 'SIGN_UP');
};

export const a_subscriptionClicked = () => {
  if (isDevelopment) return;
  const { app, user } = reduxStore.getState();
  const eventName = 'Subscription Clicked';
  const [activeSubscription] = app.subscriptions.filter(
    (subscription) => subscription.isDefault,
  );
  const { stripePrice, paypalPlanId } = activeSubscription;
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_learnMoreClicked = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  const eventName = 'Learn More Clicked';
  dataLayer.push({
    eventValue: 0,
    eventLabel: '',
    event: 'GTMevent',
    eventAction: 'click',
    eventCategory: eventName,
  });
  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_photoUploadKlaviyo = (uploaded, cart_url) => {
  if (isDevelopment) return;
  let urls = uploaded?.map((u) => u.secureUrl).filter((u) => u);
  if (!urls?.length) {
    return;
  }
  const arrayOfBoards = [];
  let cloudinaryURL;
  const folder = isProduction
    ? 'RemarketingImagesProduction'
    : 'RemarketingImagesStaging';
  for (let [i, url] of urls.entries()) {
    let imageUrl = url.split('/').slice(3).join('/');
    arrayOfBoards.push({
      [`secureUrl ${i + 1}`]: url,
    });
    cloudinaryURL = `https://res.cloudinary.com/storyboards/image/upload/t_cart1/${folder}/${imageUrl}`;
    arrayOfBoards.push({
      imageUrl,
      [`cloudinaryURL ${i + 1}`]: cloudinaryURL,
    });
    reduxStore.dispatch({ type: 'CLOUDINARY_URL', payload: cloudinaryURL });
  }
  const klaviyoObj = Object.assign(...arrayOfBoards);
  const { app, user, boards } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const { boards: tiles } = boards;
  klaviyoObj.boards = tiles;
  klaviyoObj.cart_url = cart_url;
  klaviyoObj.cart_id = app.remarketingId;
  klaviyoObj.totalPhoto = tiles?.length || 0;

  klaviyoEmail && klaviyo.trackPost('Photo Upload', klaviyoEmail, klaviyoObj);
  _learnq.push(['track', 'Added to Cart', klaviyoObj]);
};

export const a_openShippingForm = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'Open Shipping Form';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_addAddress = (address, cart_url) => {
  if (isDevelopment) return;
  const { app, user, boards } = reduxStore.getState();
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  const { boards: tiles } = boards;
  const cart_id = app.remarketingId;
  pixelSetup({ ...user, email: klaviyoEmail });
  const eventName = 'Add Address';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer(
    { eventID, eventName },
    { email: user.email, phone: user.phone },
  );
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });
  const klaviyoObj = {
    address,
    cart_id,
    cart_url,
    boards: tiles,
    totalPhoto: tiles?.length || 0,
  };

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail, klaviyoObj);

  umami?.track('add_address', { ...klaviyoObj });
};

export const a_addCreditCard = (cart_url) => {
  if (isDevelopment) return;
  const { app, user } = reduxStore.getState();
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  pixelSetup(user);
  const cart_id = app.remarketingId;
  const eventName = 'Add Credit Card';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });
  const { boards } = reduxStore.getState().boards;

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      boards,
      cart_id,
      cart_url,
    });

  umami?.track('add_credit_card', { ...boards });
};

export const a_addPhoneNumber = (phoneNumber) => {
  if (isDevelopment) return;
  const { klaviyoEmail } = reduxStore.getState().user;
  klaviyoEmail &&
    klaviyo.identifyPost({ email: klaviyoEmail, phone_number: phoneNumber });
};

export const a_snapSignUp = (email) => {
  if (isDevelopment) return;
  window.snaptr('track', 'ADD_CART', { email });
};

export const a_checkoutClick = () => {
  if (isDevelopment) return;
  const { app, user, boards, coupon } = reduxStore.getState();
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  const { boards: tiles } = boards;
  const b_disc = coupon?.coupon?.b_disc || 0;
  const discountedPriceOneBoard = (
    app.priceOne -
    (app.priceOne / 100) * b_disc
  ).toFixed(1);
  pixelSetup(user);
  const eventName = 'View Checkout';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });

  umami?.track('checkout_pressed');

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'initiate-checkout',
    eventLabel: '',
    ecommerce: {
      currencyCode: coupon.currency || 'USD',
      checkout: {
        actionField: {
          step: 1,
        },
        products: [
          {
            name: 'board', // Product name
            id: 'board', // Product identifier/SKU
            price: discountedPriceOneBoard, // Product price (including discount amount)
            category: '', // Product category
            brand: 'StoryBoards',
            quantity: tiles?.length,
          },
        ],
      },
    },
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
  _learnq.push([
    'track',
    'Started Checkout',
    {
      products: [
        {
          name: 'board',
          id: 'board',
          price: discountedPriceOneBoard,
          category: '',
          brand: 'StoryBoards',
          quantity: tiles?.length,
        },
      ],
    },
  ]);
};

export const a_purchase = (order, boards = [], totalPrice) => {
  if (isDevelopment) return;
  const { user, coupon, checkout } = reduxStore.getState();
  const { city, email, state, street, postalCode, klaviyoEmail } = user;
  const { discount } = checkout;
  const boardsObject = Object.assign(
    {},
    ...boards.map((board, index) => ({ [index + 1]: board.secureUrl })),
  );
  const currency = coupon.currency || 'USD';
  const product_id = coupon.coupon?.name || 'sb01';

  const fbData = {
    value: totalPrice,
    currency,
    contents: [{ id: product_id, quantity: boards?.length }],
    content_type: 'product',
  };
  const eventName = 'Purchase';
  const eventID = `${eventName}.${nanoid(8)}`;
  pixelSetup(user);
  window.fbq &&
    window.fbq('trackSingle', pixelId, eventName, fbData, { eventID });
  sendFbDataToServer(
    { eventID, eventName },
    { email, phone: user.phone },
    fbData,
  );

  umami?.track('purchase', {
    type: order.oneTimeOrSubscription,
    amount: Number(totalPrice).toFixed(2),
  });

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventValue: Math.round(totalPrice),
    eventLabel: '',
  });

  if (discount?.couponAmountToDeduct) {
    dataLayer.push({
      event: 'GTMevent',
      eventCategory: 'Store Credit',
      eventAction: 'click',
      eventValue: Math.round(discount?.couponAmountToDeduct),
      eventLabel: '',
    });
  }

  (klaviyoEmail || email) &&
    klaviyo.trackPost(eventName, klaviyoEmail || email, {
      city,
      state,
      street,
      postalCode,
      orderId: order.orderNumber,
      $value: totalPrice,
      boards: boards.length,
      subscription: order.oneTimeOrSubscription,
      storeCredit: discount?.couponAmountToDeduct,
      ...boardsObject,
    });

  window.snaptr('track', 'PURCHASE', { price: totalPrice, currency: 'usd' });
};

export const a_applePayPurchase = (boards, totalPrice) => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  pixelSetup(user);
  const boardsObject = Object.assign(
    {},
    ...boards.map((board, idx) => ({ [idx + 1]: board.secureUrl })),
  );

  const eventName = 'ApplePay Purchase';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventValue: Math.round(totalPrice),
    eventLabel: '',
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      Value: totalPrice,
      ...boardsObject,
    });

  umami?.track('applepay_purchase');
};

export const a_previewPhoto = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { email, klaviyoEmail } = user;
  pixelSetup(user);
  const eventName = 'Preview Photo';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);

  umami?.track('preview_photo');
};

export const a_adjustCrop = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'Adjust Crop';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);

  umami?.track('adjust_crop');
};

export const a_removePhoto = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { email, klaviyoEmail } = user;
  pixelSetup(user);
  const eventName = 'Remove Photo';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);

  umami?.track('remove_photo');
};

export const a_FAQ = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'FAQ';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_askForHelp = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'Ask for Help';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_voucherifyCoupon = (coupon) => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Coupon Activated',
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail &&
    klaviyo.trackPost('Coupon Activated', klaviyoEmail, { ...coupon });

  umami?.track('coupon_activated', { ...coupon });
};

export const a_checkoutError = (error) => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'Checkout Error';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: error,
    eventValue: 0,
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      ...error,
    });

  umami?.track('checkout_error', { ...error });
};

export const a_menuTapped = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { klaviyoEmail } = user;

  const eventName = 'Menu Tapped';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);

  umami?.track('menu_tapped');
};

export const a_klaviyoFormOpen = () => {
  if (isDevelopment) return;

  const eventName = 'Klaviyo Form Open';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'loading',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_popupEmailDisplayed = () => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Upload Email Capture',
    eventAction: 'Displayed',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_popupEmailSubmitted = () => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Upload Email Capture',
    eventAction: 'Submitted',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_klaviyoFormSubmit = () => {
  if (isDevelopment) return;

  const eventName = 'Klaviyo Form Submit';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_klaviyoFormClose = () => {
  if (isDevelopment) return;

  const eventName = 'Klaviyo Form Close';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_popupLaunching = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { email, klaviyoEmail } = user;
  pixelSetup(user);
  const eventName = 'Popup Launched';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_popupPressed = () => {
  if (isDevelopment) return;
  const { user } = reduxStore.getState();
  const { email, klaviyoEmail } = user;
  pixelSetup(user);
  const eventName = 'Popup Pressed';
  const eventID = `${eventName}.${nanoid(8)}`;
  ReactPixel.trackCustom(eventName, { eventID });
  sendFbDataToServer({ eventID, eventName }, { email, phone: user.phone });
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail && klaviyo.trackPost(eventName, klaviyoEmail);
};

export const a_placeOrderPressed = (complete, cart_url) => {
  if (isDevelopment) return;
  const { app, user, boards } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const tiles = boards.boards || [];
  const cart_id = app.remarketingId;
  const eventName = 'Place Order Pressed';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: complete ? 'Complete' : 'Incomplete',
    eventValue: 0,
  });

  const cloudinaryURLs = {};
  tiles.forEach((o) => {
    let keys = Object.keys(o).filter((key) => key.startsWith('cloudinaryURL'));
    if (keys.length > 0) {
      let key = keys[0];
      cloudinaryURLs[key] = o[key];
    }
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      'Order completed': complete ? 'Complete' : 'Incomplete',
      cart_id,
      cart_url,
      boards: tiles,
      totalPhoto: tiles.length,
      ...cloudinaryURLs,
    });

  umami?.track('place_order_pressed', {
    completed: complete ? 'Complete' : 'Incomplete',
    cart_url,
    cart_id,
    boards: tiles,
    totalPhoto: tiles.length,
    ...cloudinaryURLs,
  });
};

export const a_orderCompleted = (order) => {
  if (isDevelopment) return;

  const isSub = order.oneTimeOrSubscription === 'subscription';

  const tags = [];

  if (order.oneTimeOrSubscription === 'subscription') {
    tags.push('subscription');

    window.gtag('event', 'conversion', {
      send_to: 'AW-781049647/599yCMmu5o8ZEK--t_QC',
    });

    const { user } = reduxStore.getState();
    const { klaviyoEmail } = user;

    let eventName = 'Subscribe';
    let eventID = `${eventName}.${nanoid(8)}`;

    window.fbq &&
      window.fbq(
        'trackSingle',
        pixelId,
        eventName,
        { predicted_ltv: '120', value: order.totalPrice, currency: 'USD' },
        { eventID },
      );

    sendFbDataToServer(
      { eventID, eventName },
      { email: user.email, phone: user.phone },
      { predicted_ltv: '120', value: order.totalPrice, currency: 'USD' },
    );

    dataLayer.push({
      eventValue: 0,
      event: 'GTMevent',
      eventAction: 'accept',
      eventLabel: 'subscribed',
      eventCategory: eventName,
    });

    klaviyoEmail && klaviyo.trackPost('subscribe accept', klaviyoEmail);
  }

  post('/analytics/northbeam', null, {
    data: [
      {
        products: [
          {
            id: 'SB01',
            name: 'SB-Tiles',
            quantity: order.quantity,
            price:
              (isSub ? order.totalWithSubscription : order.totalPrice) /
              order.quantity,
          },
        ],
        order_id: order.id,
        customer_id: order.customer,
        time_of_purchase: new Date(),
        tax: 0,
        currency: 'USD',
        order_tags: tags,
        purchase_total: isSub ? order.totalWithSubscription : order.totalPrice,
      },
    ],
  }).catch(() => {});

  try {
    window.Northbeam.firePurchaseEvent({
      taxPrice: 0,
      id: order.id,
      coupons: order.coupon,
      currency: order.currency,
      customerId: order.customer,
      order_tags: tags,
      shippingPrice: order.shipping?.cost || 0,
      lineItems: [
        {
          productId: 'SB01',
          productName: 'SB-Tiles',
          quantity: order.quantity,
          variantId: order.material,
          variantName: order.material,
          price:
            (isSub ? order.totalWithSubscription : order.totalPrice) /
            order.quantity,
        },
      ],
      totalPrice: isSub ? order.totalWithSubscription : order.totalPrice,
    });
  } catch (error) {
    console.log(error);
  }

  const products = [
    {
      name: order.material,
      id: 'board', // Product identifier/SKU
      order_id: order.id,
      value: isSub ? order.totalWithSubscription : order.totalPrice,
      price:
        (isSub ? order.totalWithSubscription : order.totalPrice) /
        order.quantity, // Product price (per quantity of one)
      category: 'board',
      quantity: order.quantity,
      brand: 'StoryBoards',
      coupon: order.coupon,
      item_category5: order.oneTimeOrSubscription,
    },
  ];

  if (order.shipping.cost > 0) {
    products.push({
      name: 'rushService',
      id: 'rushService', // Product identifier/SKU
      order_id: order.id,
      value: order.shipping.cost,
      price: order.shipping.cost,
      category: '',
      quantity: 1,
      brand: 'StoryBoards',
      coupon: order.coupon,
    });
  }

  dataLayer.push({
    event: 'GTMecommerce',
    eventCategory: 'enhanced-ecommerce',
    eventAction: 'purchase',
    eventLabel: 'complete ',
    paymentMethod: order.paymentType,
    discountAmount: order.discountAmount,
    ecommerce: {
      actionField: {
        id: order.id, // Unique back-end transaction identifier
        affiliation: 'StoryBoards',
        revenue:
          (isSub ? order.totalWithSubscription : order.totalPrice) +
          order.shipping.cost, // Total amount a user is charged with
        tax: '0.00',
        shipping: '0.00',
        currency: order.currency || 'USD',
        coupon: order.coupon,
      },
      products,
    },
  });

  umami?.track('order_completed');
};

export const a_payPalButtonPressed = (cart_url) => {
  if (isDevelopment) return;
  const { app, user, boards } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const cart_id = app.remarketingId;

  const eventName = 'PayPal Button Pressed';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      cart_id,
      cart_url,
      boards: boards.boards,
    });

  umami?.track('paypal_pressed');
};

export const a_klarnaButtonPressed = (cart_url) => {
  if (isDevelopment) return;
  const { app, user, boards } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const cart_id = app.remarketingId;

  const eventName = 'Klarna Button Pressed';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      cart_id,
      cart_url,
      boards: boards.boards,
    });

  umami?.track('klarna_pressed');
};

export const a_applePayPressed = (cart_url) => {
  if (isDevelopment) return;
  const { app, user, boards } = reduxStore.getState();
  const { klaviyoEmail } = user;
  const cart_id = app.remarketingId;

  const eventName = 'ApplePay Button Pressed';

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: eventName,
    eventAction: 'click',
    eventLabel: '',
    eventValue: 0,
  });

  klaviyoEmail &&
    klaviyo.trackPost(eventName, klaviyoEmail, {
      cart_id,
      cart_url,
      boards: boards.boards,
    });

  umami?.track('applepay_pressed');
};

export const a_reuploadImages = (numOfTiles, done) => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Image Upload',
    eventAction: 'reupload',
    eventLabel: done ? 'Done' : 'Started',
    eventValue: numOfTiles,
  });
};

export const a_materialSelected = (material) => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Material Selected',
    eventAction: 'selected',
    eventLabel: material,
  });
  umami?.track('material_selected', { material });
};

export const a_materialSizeSelected = (size) => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Material Size Selected',
    eventAction: 'selected',
    eventLabel: String(size),
  });
  umami?.track('material_size_selected', { size });
};

export const a_networkFailure = () => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Network Failure',
    eventAction: 'upload',
    eventLabel: '',
    eventValue: 0,
  });
};

export const a_upsellShown = () => {
  if (isDevelopment) return;
  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Upsell',
    eventAction: 'show',
    eventLabel: 'Upsell offer shown',
    eventValue: 0,
  });
};

export const a_upsellAccepted = (order) => {
  if (isDevelopment) return;

  // 	try {
  //     window.Northbeam.firePurchaseEvent({
  //       taxPrice: 0,
  //       id: order.id,
  //       shippingPrice: 0,
  //       coupons: order.coupon,
  //       currency: order.currency,
  //       customerId: order.customer,
  //       totalPrice: order?.upsell?.price,
  //       lineItems: [
  //         {
  // 		  quantity: 1,
  //           productId: 'Upsell',
  //           productName: 'Upsell',
  //           variantId: order.material,
  //           variantName: order.material,
  //           price: order?.upsell?.price / 1,
  //         },
  //       ],
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }

  // 	post('/analytics/northbeam', null, {
  // 		data: [{
  // 		  products: [
  // 			{
  // 			  id: `Upsell`,
  // 			  name: 'Upsell',
  // 			  quantity: 1,
  // 			  price: order.upsell.price,
  // 			},
  // 		  ],
  // 		  order_id: order.id,
  // 		  customer_id: order.customer,
  // 		  time_of_purchase: new Date(),
  // 		  tax: 0,
  // 		  currency: 'USD',
  // 		  purchase_total: order.upsell.price,
  // 		}],
  // 	  });

  dataLayer.push({
    event: 'GTMevent',
    eventCategory: 'Upsell',
    eventAction: 'accept',
    eventLabel: 'Upsell offer accepted',
    eventValue: 0,
  });

  // dataLayer.push({ ecommerce: null });

  // dataLayer.push({
  // 	event: 'GTMecommerce',
  // 	eventCategory: 'enhanced-ecommerce',
  // 	eventAction: 'purchase',
  // 	eventLabel: 'complete ',
  // 	ecommerce: {
  // 		actionField: {
  // 			id: order.id + '_u', // Unique back-end transaction identifier
  // 			affiliation: 'StoryBoards',
  // 			revenue: order.upsell.price, // Total amount a user is charged with
  // 			tax: '0.00',
  // 			shipping: '0.00',
  // 			currency: order.currency || "USD",
  // 		},
  // 		products: [
  // 			{
  // 				name: 'upsell',
  // 				id: 'upsell', // Product identifier/SKU
  // 				order_id: order.id + '_u',
  // 				value: order.upsell.price,
  // 				price: order.upsell.price, // Product price (per quantity of one)
  // 				category: '',
  // 				quantity: 1,
  // 				brand: 'StoryBoards',
  // 			},
  // 		],
  // 		_clear: true,
  // 	},
  // });
};
